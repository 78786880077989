import { ro } from 'vuetify/lib/locale';

export default {
  ...ro,
  dataIterator: {
    loadingText: 'Se încarcă...'
  },
  ok: 'OK',
  cancel: 'Renunță',
  dataTable: {
    ...ro.dataTable,
    columnSelector: 'Selector coloane'
  },
  dataTableFilters: {
    c: 'Conține',
    nc: 'Nu conține',
    sw: 'Începe cu',
    ew: 'Se termină cu',
    eq: 'Egal',
    neq: 'Nu este egal',
    lt: 'Mai mic ca',
    gt: 'Mai mare ca',
    lte: 'Mai mic ca egal ca',
    gte: 'Mai mare sau egal ca'
  },
  validators: {
    fieldRequired: 'Câmpul este obligatoriu!',
    invalidEmailAddress: 'Adresa de email invalidă!'
  },
  general: {
    noConnection: 'Nu se poate face conexiunea la serviciu!',
    logout: 'Ieșire',
    backTo: 'Înapoi la {0}'
  },
  buttons: {
    add: 'Adaugă',
    edit: 'Modifică',
    delete: 'Șterge',
    save: 'Salvează',
    send: 'Trimite',
    update: 'Modifică',
    filters: 'Filtrează',
    export: 'Exportă',
    import: 'Importă',
    download: 'Descarcă',
    downloadAll: 'Descarcă toate',
    close: 'Închide'
  },
  statuses: {
    active: 'Activ',
    inactive: 'Inactiv',
    yes: 'Da',
    no: 'Nu',
    open: 'Deschis',
    closed: 'Închis'
  },
  filters: {
    apply: 'Aplică',
    reset: 'Resetează',
    dateSuffixBegin: 'început',
    dateSuffixEnd: 'sfârșit'
  },
  dialogs: {
    about: {
      title: 'Despre',
      version: 'Versiunea {0}',
      moreText: 'Toate drepturile rezervate.'
    }
  },
  views: {
    dashboard: {
      title: 'Dashboard',
      receivedInvoicesLabel: 'Facturi primite',
      issuedInvoicesLabel: 'Facturi emise',
      issuedInvoicesErrorsLabel: 'Facturi emise erori',
      messagesLabel: 'Mesaje',
      usersLabel: 'Utilizatori',
      totalLabel: 'Total',
      prevMonthLabel: 'Luna precedentă',
      currentMonthLabel: 'Luna curentă',
      adminsLabel: 'Administratori',
      operatorsLabel: 'Operatori'
    },
    login: {
      title: 'Autentificare',
      button: 'Autentifică-te',
      username: 'Utilizator',
      password: 'Parola',
      noLogin: 'Nu s-a putut autentifica utilizatorul!'
    },
    forgotPassword: {
      title: 'Ai uitat parola',
      button: 'Trimite',
      successMessage: 'Pentru a reseta parola te rugăm să urmezi pașii din emailul primit.'
    },
    resetPassword: {
      title: 'Resetează parola',
      button: 'Resetează parola',
      newPassword: 'Parola nouă',
      confirmPassword: 'Confirmă parola'
    },
    profile: {
      title: 'Profil'
    },
    changePassword: {
      title: 'Schimbă parola',
      oldPassword: 'Parola veche',
      newPassword: 'Parola nouă',
      confirmPassword: 'Confirmă parola'
    },
    users: {
      title: 'Utilizatori',
      createText: 'Utilizatorul "{0}" a fost adăugat cu succes.',
      updateText: 'Utilizatorul "{0}" a fost modificat cu succes.',
      deleteText: 'Utilizatorul "{0}" a fost șters.',
      confirmDeleteTitle: 'Șterge Utilizator',
      confirmDeleteText: 'Ești sigur că vrei să ștergi utilizatorul "{0}"?',
      nameLabel: 'Nume',
      usernameLabel: 'Utilizator',
      passwordLabel: 'Parola',
      roleLabel: 'Rol',
      positionLabel: 'Poziție',
      phoneLabel: 'Telefon',
      statusLabel: 'Stare',
      tenantLabel: 'Tenant',
      roles: {
        sa: 'Super Admin',
        admin: 'Administrator',
        operator: 'Operator'
      }
    },
    invoices: {
      title: 'Facturi',
      tabs: {
        issuedInvoices: 'Facturi emise',
        receivedInvoices: 'Facturi primite',
        receivedMessages: 'Mesaje primite'
      },
      statuses: {
        not_processed: 'Neprocesată',
        processing: 'În procesare',
        error: 'Eroare',
        processed_ok: 'Procesată'
      },
      resend: {
        tooltip: 'Retrimite la ANAF',
        confirmTitle: 'Ești sigur că vrei să retimiti?',
        confirmText: 'Factura va fi trimiă iar la ANAF.'
      },
      export: {
        nameLabel: 'Nume fișier',
        allPagesLabel: 'Toate paginile',
        currentPageLabel: 'Pagina curentă ({0})',
        startDateLabel: 'De la data',
        stopDateLabel: 'Până la data',
        exportOptionsLabel: 'Opțiuni export',
        selectPeriodLabel: 'Selectează perioada'
      },
      import: {
        templateFileButton: 'Fișier template',
        fileLabel: 'Fișier',
        importedSuccessMessage: 'Fișier importat cu succes.'
      },
      logs: {
        title: 'Loguri {0}',
        idUploadLabel: 'ID upload',
        idDownloadLabel: 'ID download',
        statusLabel: 'Stare',
        errorMessageLabel: 'Mesaj eroare',
        lastUpdateLabel: 'Ultimul update',
        anafStatusLabel: 'ANAF stare',
        anafResponseLabel: 'ANAF răspuns'
      },
      associatedFilesMenuItem: 'Vezi fișierele asociate',
      logsMenuItem: 'Vezi logurile',
      invoiceTagsMenuItem: 'Editeaza tagurile',
      savedText: 'Factura "{0}" salvată cu success.',
      filesTitle: 'Fișierele facturii {0}',
      invoiceNumberLabel: 'Nr factură',
      issueDateLabel: 'Dată emitere',
      dueDateLabel: 'Pană la data',
      issuerNameLabel: 'Emitent',
      issuerVatNumberLabel: 'Emitent cod TVA',
      issuerCompanyNameLabel: 'Nume companie emitent',
      recipientNameLabel: 'Destinatar',
      recipientVatNumberLabel: 'Destinatar cod TVA',
      recipientCompanyNameLabel: 'Nume companie destinatar',
      idUploadLabel: 'ID upload',
      idDownloadLabel: 'ID download',
      statusLabel: 'Stare',
      tagsLabel: 'Taguri',
      errorMessageLabel: 'Mesaj eroare',
      valueWithoutVatLabel: 'Valoare fără TVA',
      valueVatLabel: 'Valoare TVA',
      valueWithVatLabel: 'Valoare cu TVA',
      lastUpdateLabel: 'Ultimul update',
      typeLabel: 'Tip',
      idRequestLabel: 'ID solicitare',
      detailsLabel: 'Detalii',
      messageLabel: 'Mesaj',
      dateLabel: 'Data'
    },
    files: {
      nameLabel: 'Nume',
      typeLabel: 'Tip',
      descriptionLabel: 'Descriere'
    },
    tags: {
      title: 'Etichete',
      nameLabel: 'Nume etichetă',
      searchLabel: 'Caută',
      menuItem: 'Modifică etichetele',
      saveMessage: 'Etichetele au fost modificate.'
    }
  }
};
