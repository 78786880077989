
import Vue from 'vue';

import { empty } from '@/utils/helpers';
import intl from '@/utils/intl';

import UserAvatar from '@/components/user-avatar.vue';
import LanguagePanel from '@/components/language-panel.vue';
import TenantPanel from '@/components/tenant-panel.vue';
import AboutDialog from '@/components/dialogs/about.vue';
import ProfileModal from '@/components/modals/profile.vue';
import ChangePasswordModal from '@/components/modals/change-password.vue';
import TagsModal from '@/components/modals/tags-modal.vue';

export default Vue.extend({
  props: {
    title: String
  },
  components: {
    UserAvatar,
    LanguagePanel,
    TenantPanel,
    AboutDialog,
    ProfileModal,
    ChangePasswordModal,
    TagsModal
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      drawer: true,
      mini: false,
      user: this.$store.getters.user,
      logoutLoader: false,
      flashAlert: {
        timeID: -1,
        show: false,
        type: 'info',
        message: ''
      }
    }
  },
  computed: {
    // App menus
    dashboardTitle(): string {
      return intl.t('views.dashboard.title');
    },
    usersTitle(): string {
      return intl.t('views.users.title');
    },
    invoicesTitle(): string {
      return intl.t('views.invoices.title');
    },
    // User menu
    profileTitle(): string {
      return intl.t('views.profile.title');
    },
    tagsTitle(): string {
      return intl.t('views.tags.title');
    },
    changePasswordTitle(): string {
      return intl.t('views.changePassword.title');
    },
    logoutTitle(): string {
      return intl.t('general.logout');
    }
  },
  methods: {
    empty,
    async logout(): Promise<void> {
      this.logoutLoader = true;
      await this.$store.dispatch('logOut');
      await this.$router.push({
        name: 'login',
        query: { redirect: this.$route.path }
      });
    },
    setFlash(type: string, message: string): void {
      this.flashAlert.show = true;
      this.flashAlert.type = type;
      this.flashAlert.message = message;
      clearTimeout(this.flashAlert.timeID);
      this.flashAlert.timeID = setTimeout(() => this.flashAlert.show = false, 20000);
    }
  }
});
