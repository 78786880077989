
import Vue from 'vue';
import Http, { HttpError } from '@/utils/Http';
import { VForm } from 'vuetify/types/custom';
import { empty } from '@/utils/helpers';
import intl from '@/utils/intl';
import validators from '@/utils/validators';

export default Vue.extend({
  name: 'TagsModal',
  mixins: [validators],
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      modal: false,
      loading: false,
      tags: [] as string[],
      tagForm: {
        index: -1,
        name: null as string | null
      },
      submitLoading: false
    };
  },
  computed: {
    closeButton(): string {
      return intl.t('buttons.close');
    },
    saveButton(): string {
      return intl.t('buttons.save');
    }
  },
  watch: {
    async modal(status): Promise<void> {
      if (status) {
        await this.loadTags();
      }
    }
  },
  methods: {
    addButtonLabel(index: number): string {
      return index !== -1 ? intl.t('buttons.update') : intl.t('buttons.add');
    },
    setLabelName(name: string): string {
      return intl.t(`views.tags.${name}Label`);
    },
    async loadTags(): Promise<void> {
      this.loading = true;
      this.tags = [];
      try {
        const response = await (new Http()).get(`/v1/invoices/tags`);
        if (!empty(response.data)) {
          this.tags = response.data;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.loading = false;
    },
    saveTag(): void {
      if ((this.$refs.form as VForm).validate()) {
        if (this.tagForm.index !== -1) {
          this.$set(this.tags, this.tagForm.index, this.tagForm.name);
        } else {
          this.tags.push(String(this.tagForm.name));
        }
        this.resetForm();
      }
    },
    editTag(index: number): void {
      this.tagForm = {
        index,
        name: this.tags[index]
      };
    },
    deleteTag(index: number): void {
      this.tags.splice(index, 1);
    },
    resetForm(): void {
      (this.$refs.form as VForm).reset();
      this.tagForm = {
        index: -1,
        name: null
      };
    },
    close(): void {
      this.modal = false;
      this.resetForm();
    },
    async save(): Promise<void> {
      this.submitLoading = true;
      try {
        const tagsToSave = this.tags.length > 0 ? this.tags : ["NULL"];
        await (new Http()).put(`/v1/invoices/tags`, JSON.stringify(tagsToSave));
        this.$root.$children[0].$children[0].setFlash('success', intl.t('views.tags.saveMessage'));
        this.close();
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.submitLoading = false;
    }
  }
});
